import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import Notify from "@/helpers/Notify";
import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";
import { useRouter } from "next/router";
import PathResolver from "@/helpers/PathResolver";
import Loading from "@/components/animations/Loading";

interface ProfilePictureSelectorInterface extends ModalInterface { }

const ProfilePictureSelector: React.FC<ProfilePictureSelectorInterface> = ({ isOpen, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedPicture, setSelectedPicture] = useState<string | null>(null);

	const { member, createMemberFromAPIResponse } = useMember();
	const router = useRouter();

	const closeModal = () => {
		onClose();
		setIsLoading(false);
	};

	const avatars = Array.from({ length: 10 }, (_, i) => PathResolver.getProfilePicturePath(`${i + 1}.png`, 'man')).concat(
		Array.from({ length: 10 }, (_, i) => PathResolver.getProfilePicturePath(`${i + 1}.png`, 'woman'))
	);

	const handleImageSelect = (imageName: string) => {
		setSelectedPicture((prev) => {
			if (prev === imageName) return null;
			else return imageName;
		});
	};

	const confirmSelection = () => {
		if (selectedPicture) {
			const api = new Api('members', 'sa');
			const request = api.request(member, {
				avatar: selectedPicture
			});
			setIsLoading(true);
			api.post(request).then((response) => {
				if (response.success) {
					const { result } = response.data;
					if (result) {
						createMemberFromAPIResponse(response);
						closeModal();
						Notify.success("Imagem de perfil atualizada!").center();
					}
				}
			}).finally(() => setIsLoading(false));
		}
		else Notify.Warn("Você precisa especificar uma imagem para continuar");
	}

	const AvatarContainer: React.FC = React.memo(() => {
		return (
			<>
				{avatars.map((avatar) => (
					<div
						key={avatar}
						className={`cursor-pointer border-box shadow-md p-2 rounded-md ${selectedPicture === avatar ? 'border-t-4 border-orange-500' : 'border-t-4 border-gray-200 hover:border-orange-500'}`}
						onClick={() => handleImageSelect(avatar)}
					>
						<img src={`${avatar}`} alt={`Avatar ${avatar}`} className="mx-auto h-24 w-24 rounded-full" />
					</div>
				))}</>
		)
	})

	const CancelButton: React.FC = React.memo(() => {
		return (
			<div className="w-full flex justify-center">
				<button className={`mt-2 mx-4 py-1 px-4 rounded bg-slate-600 text-white hover:scale-105 transition-all duration-100`}
					onClick={closeModal}
				>
					Cancelar
				</button>
			</div>
		)
	});

	const ConfirmButton: React.FC = React.memo(() => {
		const isDisabled = !selectedPicture;
		return (
			<div className="w-full flex justify-center">
				<button className={`mt-2 mx-4 py-1 px-4 rounded ${isDisabled ? 'bg-slate-400 cursor-not-allowed' : 'bg-orange-500 hover:scale-105 transition-all duration-100'} text-white`}
					onClick={confirmSelection} disabled={isDisabled}
				>
					Confirmar
				</button>
			</div>
		)
	});

	const Body: React.FC = React.memo(() => {
		return (
			<>
				<div className="grid grid-cols-4 gap-4 px-2 pb-5 max-h-[68vh] overflow-y-auto">
					<AvatarContainer />
				</div>
				<a href="https://www.flaticon.com/br/icones-gratis/perfil" style={{ fontSize: 10 }} title="perfil ícones">Ícones criados por andinur - Flaticon</a>
				<div className="flex flex-row mt-5">
					<CancelButton />
					<ConfirmButton />
				</div>
			</>
		)
	});

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal} closable={false}>
			<div className="p-4 flex flex-col justify-center min-h-[90vh] overflow-hidden">
				<h2 className="text-2xl font-bold mb-2">Selecione seu Avatar</h2>
				<p className="mb-4 text-gray-600">Por favor, escolha um avatar para representá-lo na plataforma. Você pode mudar isso mais tarde nas configurações do perfil.</p>
				{
					isLoading ? <Loading /> : <Body />
				}
			</div>
		</HandleModal>
	);
};

export default ProfilePictureSelector;
