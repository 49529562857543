import StepContainer from "@/components/partials/onboarding/steps/StepContainer";
import { useMember } from "@/contexts/member/MemberContext";
import { UserRoles } from "@/types/member";
import { Transition } from "@headlessui/react";
import trophy from '@public/animations/trophy.json';
import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";


interface ViewProps {
	view?: () => void;	
}

const OverviewStep: React.FC<ViewProps> = ({view}) => {
	const { member } = useMember();
	const [showChildren, setShowChildren] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShowChildren(true)
		},100)
	}, [view])

	return (
		<StepContainer>
			<Transition
				show={showChildren}
				enter="transition-opacity duration-1000"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-1000"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<p className="text-xl text-center mt-5">Obrigado por concluir o Onboarding</p>
				
				<div className="w-full h-full max-w-lg flex flex-col items-center mb-4">
					<img
						src={member.avatar || 'https://picsum.photos/200'}
						alt="Avatar"
						className="w-28 h-28 rounded-full object-cover mb-2"
					/>
					<div className="flex flex-col items-center text-center">
						<p className="text-lg font-semibold">{member.firstName} {member.lastName}</p>
						<p className="text-sm text-gray-500">{member.email}</p>
						<p className="text-sm text-gray-500">{getFriendlyRoleName(member.role)}</p>
					</div>
					<div className="mt-7" >
						<Lottie
							loop={false}
							play
							style={{ height: 150 }}
							animationData={trophy}
						/>
					</div>
				</div>
			</Transition>
		</StepContainer>
	)
}

export default OverviewStep;

function getFriendlyRoleName(role: UserRoles | null): string
{
	switch (role)
	{
		case 'admin': return 'Administrador';
		case 'manager': return 'Gerente';
		case 'marketing': return 'Marketing';
		default: return 'Cartazista';
	}
}
