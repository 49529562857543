import React from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useMember } from "@/contexts/member/MemberContext";
import EpjLoadingIcon from "../animations/EpjLoadingIcon";

const FetchingMemberModal: React.FC = () => {
	const { isFetchingData } = useMember();
	return (
		<HandleModal isOpen={isFetchingData} onClose={() => null} bgClass="none" className="none">
			<div className="flex flex-col justify-center items-center">
				<EpjLoadingIcon />
			</div>
		</HandleModal>
	)
}

export default FetchingMemberModal;
