export default class PathResolver
{
	// Todos os caminhos mapeados devem aparecer aqui.
    static paths = {
        profile_pictures: '/images/profile'
    }

    static getProfilePicturePath(pictureName: string, biologicalSex: 'man' | 'woman'): string {
        return `${PathResolver.paths.profile_pictures}/${biologicalSex}/${pictureName}`;
    }
}
