import React from "react";

interface ActionButtonProps {
	label: string;
	variant: 'primary' | 'secondary';
	type: 'button' | 'submit' | 'reset';
	onClick?: () => void | undefined;
	disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ label, variant, type, onClick, disabled = false }) => {
	const commonTheme = "text-white px-4 py-2 rounded focus:outline-none";
	const primaryTheme = disabled ? "cursor-not-allowed bg-orange-200" : "bg-orange-500 hover:bg-orange-600";
	const secondaryTheme = disabled ? "cursor-not-allowed bg-slate-200" : "bg-slate-400 hover:bg-slate-600";
	const theme = `${commonTheme} ${variant === 'primary' ? primaryTheme : secondaryTheme}`;

	return (
		<button
			type={type}
			className={theme}
			onClick={onClick ?? (() => null)}
		>
			{label}
		</button>
	)
}

export default ActionButton;
