import { useOnboarding } from '@/contexts/onboarding/OnboardingContext';
import { useStepper } from '@/contexts/onboarding/steps/StepContext';
import React, { useEffect } from 'react';

interface StepperInterface {
	onChange: (step: number) => void,
	onFinish: () => void
}

const Stepper: React.FC<StepperInterface> = ({ onChange, onFinish }) => {
    const { activeStep, setActiveStep } = useStepper();
    const steps = ['Informações Pessoais', 'Detalhes de Segurança', 'Minha Empresa', 'Overview'];
    const descriptions = ['Confira se seus dados estão corretos.', 'Suas permissões de acesso no sistema.', 'Resumo da sua Empresa.', 'Resumo do Perfil.'];

    const { isNextBtnEnabled } = useOnboarding();

	const handleChangeActiveStep = (step: number) => {
        if (!isNextBtnEnabled) return;
		setActiveStep(step);
		onChange(step);
	}

    return (
        <div className="w-full py-4 px-8">
            <div className="flex items-center justify-between space-x-4">
                {steps.map((step, index) => (
                    <div key={`step-${index}`} className="flex items-center cursor-pointer" onClick={() => handleChangeActiveStep(index)}>
                        <div className="flex flex-col items-center">
                            <div
                                className={`rounded-full h-10 w-10 flex items-center justify-center mb-2 cursor-pointer 
                                    ${index <= activeStep ? 'bg-blue-500 text-white' : 'bg-white border-2 border-gray-200 text-gray-500 hover:text-white hover:bg-blue-500'}`}
                            >
                                {index + 1}
                            </div>
                            <div className="text-center">
                                <div className={`font-semibold ${index <= activeStep ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>{step}</div>
                                <div className={`font-light ${index <= activeStep ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>{descriptions[index]}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}



export default Stepper;
