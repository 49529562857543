import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";
import { useOnboarding } from "@/contexts/onboarding/OnboardingContext";
import { useStepper } from "@/contexts/onboarding/steps/StepContext";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface StepperInterface {
	onChange: (step: number) => void,
	onFinish: () => void
}

const ButtonSteps: React.FC<StepperInterface> = ({onChange, onFinish}) => {
    const { activeStep, setActiveStep } = useStepper();
    const steps = ['Informações Pessoais', 'Detalhes de Segurança', 'Minha Empresa', 'Overview'];

    const { isNextBtnEnabled } = useOnboarding();

    const { member } = useMember();
    const router = useRouter();

    const isLastStep = activeStep === steps.length - 1;

    const handleNext = () => {
        if (activeStep < steps.length - 1)
		{
			const step = activeStep + 1;
            setActiveStep(step);
			onChange(step);
        }
    }

    const handlePrev = () => {
        if (activeStep > 0)
		{
			const step = activeStep - 1;
            setActiveStep(step);
			onChange(step);
        }
    }

    const handleFinish = async () => {
        onFinish();
        if (!member.finishOnboarding)
        {
            const api = new Api('members');
            const request = api.request(member, {
                payload: { FinishOnboarding: true }
            });
            const response = await api.post(request, 'u');
            if (response.success)
            {
                const { result } = response.data;
                if (result)
                {
                    router.reload();
                }
            }
        }
    }

	return (
        <div className="mt-2 flex justify-between items-end">
        <button className={`px-4 py-2 rounded bg-gray-500 text-white hover:bg-gray-600 ${activeStep === 0 && 'opacity-50 cursor-not-allowed'}`} onClick={handlePrev} disabled={activeStep === 0}>Voltar</button>
        <button className={`px-4 py-2 rounded ${ isNextBtnEnabled ? (isLastStep ? 'bg-orange-500 hover:bg-orange-600' : 'bg-blue-500 hover:bg-blue-600') : 'bg-gray-500 opacity-50 cursor-not-allowed' } text-white`} onClick={isLastStep ? handleFinish : handleNext} disabled={!isNextBtnEnabled} >
            {isLastStep ? 'Finalizar' : 'Avançar'}
        </button>
    </div>
	)
}


export default ButtonSteps;