import React, { useState, useEffect } from 'react';
import HandleModal from '../BlurModal';
import Api from '@/api/Api';
import Link from 'next/link';
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import creatingDesign from '@public/animations/creating-design.json';
import dogWalking from '@public/animations/dog-walking.json';
import Notify from '@/helpers/Notify';
import { useMember } from '@/contexts/member/MemberContext';
import { APIResponse } from '@/types/member';
import { ResponseObject } from '@/api/types';
import SystemInfo from '@/helpers/SystemInfo';
import ActionButton from '@/components/modals/login/partials/ActionButton';

interface LoginModalProps {
	isOpen: boolean;
	onClose: () => void;
}

interface LoginFormProps {
	closeModal: () => void;
}

interface LoginPanelProps {
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
	return (
		<HandleModal isOpen={isOpen} onClose={onClose} >
			<LoginForm closeModal={onClose} />
		</HandleModal>
	)
}

const LoginForm: React.FC<LoginFormProps> = ({ closeModal }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginSucceed, setLoginSucceed] = useState(false);
	const [isLicenseDenied, setIsLicenseDenied] = useState(false);

	const api = new Api('login', 'l');
	const userAgent = navigator.userAgent;
	const isNotCompatible = userAgent.includes('Firefox');

	const {
		member,
		createMemberFromAPIResponse,
		setIsRegistrationModalVisible
	} = useMember();

	useEffect(() => {
		if (loginSucceed && member.isLogged()) {
			Notify.Success(`Bem-vindo ao SalesTAG, ${member.firstName}`);
			closeModal();
			setLoginSucceed(false);
		}
	}, [loginSucceed, member]);

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Remove mensagem de licença se estiver em exibição.
		if (isLicenseDenied) setIsLicenseDenied(false);

		setIsLoading(true);
		const request = {
			user: email,
			password: password
		}

		api.post(request).then((response) => {
			handleResponse(response, createMemberFromAPIResponse, setLoginSucceed, setIsLicenseDenied);
		})
			.catch((err) => {
				Notify.Error("Algo inesperado aconteceu :(");
			})
			.finally(() => setIsLoading(false));
	};

	const openRegistrationModal = () => {
		closeModal();
		setIsRegistrationModalVisible(true);
	}

	return (
		<div className="min-h-fit flex items-center justify-center bg-gray-100 relative">
			<div className="bg-white p-8 rounded-lg w-[56rem] h-[36rem] flex flex-row">
				<div className="w-1/2 h-full flex flex-col items-center justify-center">
					<LoginPanel />
				</div>
				<div className="w-1/2 h-full flex flex-col items-center mt-20 ">
					{
						isNotCompatible ? (
							<>
								<div className='w-fulll flex items-center'>
									<h1 className='text-rose-500 font-bold uppercase text-center'>
										O Firefox não é compatível com o Salestag.
									</h1>
								</div>
							</>
						) : (
							<>
								{
									SystemInfo.ALLOW_REGISTRATION ? (
										<h1 className="text-2xl font-semibold mb-6 cursor-default">
											Entre ou&nbsp;
											<span className='text-orange-500 hover:underline cursor-pointer' onClick={openRegistrationModal}>
												Cadastre-se
											</span>
										</h1>
									) : (
										<h1 className="text-2xl font-semibold mb-6 cursor-default">
											Login
										</h1>
									)
								}

								<form onSubmit={handleSubmit} className="w-full p-6">
									<div className="mb-4">
										<label htmlFor="email" className="block text-gray-700 mb-2">
											Email
										</label>
										<input
											type="email"
											id="email"
											value={email}
											disabled={isLoading}
											onChange={(e) => setEmail(e.target.value)}
											className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-orange-500 hover:border-orange-500"
											required
										/>
									</div>
									<div className="mb-6">
										<label htmlFor="password" className="block text-gray-700 mb-2">
											Senha
										</label>
										<input
											type="password"
											id="password"
											value={password}
											disabled={isLoading}
											onChange={(e) => setPassword(e.target.value)}
											className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-orange-500 hover:border-orange-500"
											required
										/>
									</div>
									<div className="flex justify-between items-center">
										<ActionButton label='Cancelar' type='button' variant='secondary' onClick={closeModal} disabled={isLoading} />
										<ActionButton label='Entrar' type='submit' variant='primary' disabled={isLoading} />
									</div>
									{
										isLoading && (
											<div className="flex justify-center items-center w-full">
												<Lottie
													loop
													play
													style={{ height: 200 }}
													animationData={dogWalking}
												/>
											</div>
										)
									}
									{
										isLicenseDenied && (
											<div className="flex flex-col text-center mt-10 justify-between items-center rounded border-2 border-red-400 border-dashed">
												<span className="text-sm">Parece que sua Organização não está com a Licença de Uso ativa. Converse com o responsável do projeto ou contate nosso suporte.</span>
											</div>
										)
									}
								</form>
							</>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default LoginModal;

const LoginPanel: React.FC<LoginPanelProps> = ({ }) => {
	return (
		<div className="h-full w-full flex flex-col items-center justify-center cursor-default">
			<span className='text-2xl text-center mb-4' >Dê o próximo passo na produtividade da sua Equipe com a Criação de Cartazes</span>
			<Lottie
				loop
				play
				animationData={creatingDesign}
			/>
			<span className='mt-4 text-lg' >Venha para o <b>Salestag</b>.</span>
		</div>
	)
}

const handleResponse = (response: ResponseObject, createMember: { (response: ResponseObject): boolean; (arg0: ResponseObject): any; }, setLoginSucceed: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }, setIsLicenseDenied: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {
	if (response.success) {
		if (createMember(response as APIResponse)) {
			setLoginSucceed(true);
		}
		else Notify.Error("Não foi possível criar um membro.");
	}
	else if (response.code === 'login_failed') {
		const { type } = response.data;
		if (type === 'wrong_username') {
			Notify.Error("Ops, este e-mail não parece estar correto.");
		}
		else if (type === 'wrong_password') {
			Notify.Error("A senha digitada não corresponde à esta conta.");
		}
		else if (type === 'license_denied') {
			Notify.Warn("A Licença de Uso não está ativa.");
			setIsLicenseDenied(true);
			setTimeout(() => setIsLicenseDenied(false), 15000);
		}
		else {
			throw new Error("Falha de login: 'invalid_error_type'");
		}
	}
	else if (response.code === 'unknown_error') {
		Notify.Error("O servidor não retornou uma resposta válida.");
	}
	else {
		Notify.Error("Houve uma falha ao conectar com o servidor.");
	}
}
