import { useState, useEffect } from "react";
import StepContainer from "@/components/partials/onboarding/steps/StepContainer";
import { useMember } from "@/contexts/member/MemberContext";
import Notify from "@/helpers/Notify";
import Api from "@/api/Api";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useOnboarding } from "@/contexts/onboarding/OnboardingContext";
import { Transition } from "@headlessui/react";
import Loading from "@/components/animations/Loading";

interface ViewProps {
	view?: () => void;
}

const WelcomeStep: React.FC<ViewProps> = ({ view }) => {
	const { member, createMemberFromAPIResponse } = useMember();

	const { isNextBtnEnabled, setIsNextBtnEnabled } = useOnboarding();

	const { setProfilePictureSelectorVisibility } = useModals();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	// State para o nome e sobrenome
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");

	const [showChildren, setShowChildren] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShowChildren(true)
		}, 80)
	}, [view])

	useEffect(() => {
		if (member.isLogged()) {
			setFirstName(member.firstName);
			setLastName(member.lastName || '');
		}
	}, [member]);

	useEffect(() => {
		if (member.isLogged() && !member.avatar && isNextBtnEnabled) {
			setIsNextBtnEnabled(false);
		}
		else if (member.isLogged() && member.avatar && !isNextBtnEnabled) {
			setIsNextBtnEnabled(true);
		}
	}, [member, isNextBtnEnabled]);

	const onConfirm = async () => {
		if (!firstName || !lastName) {
			Notify.Warn("Por favor, preencha todos os campos");
			return;
		}

		const api = new Api('members');
		const request = api.request(member, {
			payload: {
				FirstName: firstName,
				Surname: lastName
			}
		});

		setIsLoading(true);
		const response = await api.post(request, 'u');
		if (response.success) {
			const { result } = response.data;
			if (result) {
				const authRequest = api.useHash(member);
				const authResponse = await api.post(authRequest, 'a');
				if (authResponse.success) {
					if (createMemberFromAPIResponse(authResponse)) {
						Notify.Success("Salvo!");
					}
					else Notify.Error("Houve um erro ao atualizar");
				}
			}
		}
		setIsLoading(false);
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target;
		if (id === 'grid-first-name') setFirstName(value);
		else if (id === 'grid-last-name') setLastName(value);
	}

	return (
		<StepContainer onConfirm={onConfirm} confirmLabel="Atualizar" >
			<Transition
				show={showChildren}
				enter="transition-opacity duration-1000"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-1000"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className={"flex flex-col items-center"}
			>
				<p className="text-xl text-center mb-5">Conte-nos um pouco sobre você</p>

				<div className="w-full max-w-lg flex flex-col items-center mb-6">
					<img
						src={member.avatar || '/images/no-img.png'}
						alt="Avatar"
						className="w-28 h-28 rounded-full object-cover mb-2"
					/>
					<p className="text-sm text-blue-500 underline cursor-pointer mb-4" onClick={() => setProfilePictureSelectorVisibility(true)}>
						Alterar avatar
					</p>
					{
						!member.avatar && (
							<p className="text-red-500 text-[12px] cursor-default mb-4">
								Você precisa escolher seu avatar antes de prosseguir!
							</p>
						)
					}
					<div className="flex flex-col items-center text-center">
						<p className="text-lg font-semibold">{firstName} {lastName}</p>
						<p className="text-sm text-gray-500">{member.email}</p>
					</div>
				</div>

				<form className="w-full max-w-lg">
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
								Nome
							</label>
							<input className="appearance-none shadow block w-full text-gray-700 border rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue-500" id="grid-first-name" type="text" placeholder="Seu Nome" value={firstName} onChange={handleInputChange} disabled={isLoading} />
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
								Sobrenome
							</label>
							<input className="appearance-none shadow block w-full text-gray-700 border rounded-lg py-3 px-4 leading-tight focus:outline-none focus:border-blue-500" id="grid-last-name" type="text" placeholder="Seu Sobrenome" value={lastName} onChange={handleInputChange} disabled={isLoading} />
						</div>
						{
							isLoading && (
								<div className="absolute bottom-[-7vh] left-1 w-full flex justify-center items-center">
									<Loading />
								</div>
							)
						}
					</div>
				</form>
			</Transition>
		</StepContainer>
	);
}

export default WelcomeStep;
