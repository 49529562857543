import React, { useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import Stepper from "@/components/partials/onboarding/Stepper";
import WelcomeStep from "@/components/partials/onboarding/steps/WelcomeStep";
import SecurityStep from "@/components/partials/onboarding/steps/SecurityStep";
import CompanyStep from "@/components/partials/onboarding/steps/CompanyStep";
import OverviewStep from "@/components/partials/onboarding/steps/OverviewStep";
import ButtonSteps from "@/components/partials/onboarding/steps/ButtonsStep";
import { StepProvider } from "@/contexts/onboarding/steps/StepContext";

interface OnboardingModalInterface extends ModalInterface { }

const OnboardingModal: React.FC<OnboardingModalInterface> = ({ isOpen, onClose }) => {
	const [step, setStep] = useState(0);

	const closeModal = () => {
		onClose();
		setTimeout(() => setStep(0), 1000);
	};

	const onChangeStep = (step: number) => setStep(step);

	const stepContent = [
		<WelcomeStep />,
		<SecurityStep />,
		<CompanyStep />,
		<OverviewStep />,
	];

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal} closable={false}>
			<div className="px-10 h-[85vh] w-[90vw] overflow-y-hidden flex flex-col justify-center">
				<div className="w-full flex justify-center">
					<h2 className="text-2xl font-bold mb-2 text-center">Bem-vindo ao Salestag</h2>
				</div>
				<StepProvider>
					<Stepper onChange={onChangeStep} onFinish={closeModal} />
					{stepContent[step]}
					<ButtonSteps onChange={onChangeStep} onFinish={closeModal} />
				</StepProvider>
			</div>
		</HandleModal>
	);
};

export default OnboardingModal;
