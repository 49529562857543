import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useMember } from "@/contexts/member/MemberContext";
import { useCompany } from "@/contexts/company/CompanyContext";

interface LicenseModalInterface { }

const LicenseModal: React.FC<LicenseModalInterface> = ({ }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { company } = useCompany();
	const { member } = useMember();

	useEffect(() => {
		if (company && (!company.licenseEnabled || !company.isLicenseValid)) {
			console.log('invalid license detected')
			setIsOpen(true);
		}
	}, [company]);

	return (
		<HandleModal isOpen={isOpen} onClose={() => null} closable={false}>
			<div className="p-4 max-h-[95vh] overflow-y-auto no-text-highlight">
				<h2 className="text-2xl text-center font-bold mb-2">Licença de uso expirada</h2>
				<p className="mt-10 text-gray-600 text-center">Por favor, entre em contato com o suporte.</p>
				<div className="w-full flex justify-center mt-6">
					<button onClick={() => member.logout()} className="bg-purple-400 hover:bg-purple-600 duration-300 py-2 px-8 rounded-lg text-white">
						Ok
					</button>
				</div>
			</div>
		</HandleModal>
	);
};

export default LicenseModal;
